import { Observable } from 'rxjs';

import {
  IGrpcOperationalEmployeeLogRequest,
  IGrpcOperationalEmployeeLogResponse
} from './supermarket/time-slot.interface';
import { IGrpcDeliveryOption } from './delivery-option.interface';
import { IGrpcDeliveryType } from './delivery-type.interface';
import { IKeyMapping } from './key-mapping.interface';
import { ICountry, ICountryGrpc, IGrpcCountry } from './location/country.interface';
import {
  IGrpcProductDetailResponse,
  IGrpcProductDetailRequest,
  IGrpcGetPutawayProductReq,
  IGrpcGetPutawayProductRes,
  IGrpcGetProductNameReq,
  IGrpcGetProductsRes,
  IGrpcProductBarcodeRes,
  IGrpcGetProductBarcodesReq,
  IGrpcGetLmsProductsReq,
  IGrpcGetLmsProductsRes,
  IGrpcGetProductWithInventoryModifiersReq,
  IGrpcGetProductWithInventoryModifiersRes,
  IGrpcGetProductsWithTrackStockReq,
  IGrpcGetProductsWithTrackStockRes
} from './product/product.interface';
import {
  IGrpcAddOrderProductRequest,
  IGrpcAddOrderProductResponse,
  IGrpcAddOrderUserRequest,
  IGrpcGetOrderProductRequest,
  IGrpcOrderRequest,
  IGrpcOrderDetailResponse,
  IGrpcOrderSummaryRequest,
  IGrpcOrderSummaryDetailResponse,
  IGrpcAddOrderUserResponse,
  ISaveOrderByPaymentIdReq,
  ISaveOrderByPaymentIdRes,
  IGrpcGetOrdersByUserIdsRes,
  IGrpcCancelOrderReq,
  IGrpcCancelOrderRes,
  IGrpcGetConsumerOrdersRes,
  IGrpcGetConsumerOrdersReq,
  IGrpcUnassignOrderPickingBatchesOrBinsAndProductsReq,
  IGrpcUserOrderStatsRes,
  IGrpcAddLocationAndStockLocationReq,
  IGrpcAddLocationAndStockLocationRes,
  IGrpcCheckLocationsExistsReq,
  IGrpcCheckLocationsExistsRes,
  IGrpcOrderDetailsForPromotionsUserReq,
  IGrpcOrderDetailsForPromotionsUserRes,
  IGrpcOrderDetailsForPromotionsProductReq,
  IGrpcOrderDetailsForPromotionsProductRes
} from './order/order.interface';
import {
  IGrpcGetCustomerOrderProductRes,
  IGrpcGetOrderProductResponse,
  IGrpcGetOrderedProductLastWeekPurchaseRes
} from './order/order-product.interface';
import {
  IGrpcEmployeeSupermarket,
  IGrpcGetSupermarketName,
  IGrpcGetSupermarketByIdReq,
  IGrpcGetSupermarketByIdRes,
  IGrpcSupermarket
} from './supermarket/super-market.interface';
import {
  IGrpcEmployee,
  IGrpcListOperationalEmployeeReq,
  IGrpcListOperationalEmployeeRes,
  IGrpcUpdateAvailabilityReq,
  IGrpcUpdateAvailabilityRes,
  IGrpcValidateEmployeeSupermarket,
  IGrpcValidateEmployeeSupermarketResponse,
  IGrpcVehicleDriverDeleteRequest,
  IGrpcVehicleDriverUpdateRequest,
  IGrpcVehicleDriverUpdateResponse
} from './employee.interface';
import { IGrpcSupplier } from './product/supplier.interface';
import {
  IGrpcViewPurchaseOrderReq,
  IGrpcViewPurchaseOrderResponse
} from './warehouse-management/wms-purchase-order.interface';
import {
  IUserGrpcValidateAssignedTo,
  IUserGrpcValidateAssignedToResponse
} from './warehouse-management/wms-putaway.interface';
import { IGrpcProductVariant, IGrpcProductVariantResponse } from './product/product-variant.interface';
import {
  IGrpcGetInventoryModifierStockReq,
  IGrpcGetInventoryModifierStockRes,
  IGrpcUpdateInventoryModifierStock
} from './product/inventory-modifiers.interface';
import {
  IGrpcGetVehicleIdReq,
  IGrpcGetVehicleIdRes,
  IGrpcVehicleDetailsRequest,
  IGrpcVehicleDetailsResponse
} from './vehicle-management.interface';
import {
  IGrpcGetOrderPickingShipmentReq,
  IGrpcGetOrderPickingShipmentRes,
  IGrpcGetOrderShipmentsReq,
  IGrpcGetOrderShipmentsRes,
  IGrpcGetPaymentAndAddressReq,
  IGrpcGetPaymentAndAddressRes,
  IGrpcGetPaymentOptionsRes,
  IGrpcGetSupermarketDetailsDriverAndPaymentReq,
  IGrpcGetSupermarketDetailsDriverAndPaymentRes
} from './order/order-shipment.interface';
import {
  IGrpcCreateUserRes,
  IGrpcCreateUserReq,
  IGrpcUser,
  IGrpcUserNameReq,
  IGrpcUserIdReq,
  IGrpcUsers,
  IGrpcUpdateUserMobileNumberReq,
  IGrpcUpdateUserMobileNumberRes,
  IGrpcCheckMobileNumberReq,
  IGrpcCheckMobileNumberRes,
  IGrpcGetUserReq,
  IGrpcGetUserRes,
  IGrpcUpdateAuthUserReq,
  IGrpcUpdateAuthUserRes,
  IUser,
  IGrpcGetTimeSlotRes,
  IGrpcGetTimeSlotReq,
  IGrpcGetAuthUserDetailReq,
  IGrpcGetAuthUserDetailRes,
  IGrpcUserBlacklistReq
} from './user.interface';
import { IGrpcSendPushNotificationReq } from './firebase.interface';
import {
  ICheckoutCard,
  ICheckoutPaymentRefundResponse,
  ICheckoutWebhookEventData,
  IGrpcGetCheckoutCardDetailReq,
  IGrpcGetCheckoutCardDetailRes,
  IGrpcInitiateOrderPickingPaymentReq
} from './checkout.interface';
import { IGrpcCommonSuccessRes } from './common.interface';
import {
  IGrpcGetStockMasterStockReq,
  IGrpcStockLocationRes,
  IGrpcStockMasterStockRes
} from './warehouse-management/wms-stock-management.interface';
import {
  IGrpcGetLanguagesByIdsReq,
  IGrpcLanguageReq,
  IGrpcGetLanguagesByIdsRes,
  ILanguage
} from './language.interface';
import {
  IGrpcCreateOrderPickingReq,
  IGrpcCreateOrderPickingRes,
  IGrpcOrderPickingGetSupermarketReq,
  IGrpcOrderPickingGetSupermarketRes,
  IGrpcListOrderPickingProductsReq,
  IGrpcListOrderPickingProductsRes,
  IUserGrpcListOrderPickingProductsReq,
  IUserGrpcListOrderPickingProductsRes,
  IGrpcListOrderPickingBatchReq,
  IGrpcListOrderPickingBatchRes,
  IGrpcListAdminOrderPickingProductsReq,
  IGrpcListAdminOrderPickingProductsRes,
  IGrpcListOrderPickingJobProductsReq,
  IGrpcListOrderPickingJobProductsRes,
  IUserGrpcCompleteOrderPickingBatchRes,
  IUserGrpcCompleteOrderPickingBatchReq
} from './fulfillment/order-picking.interface';
import { GetSupermarketsInventoryModifierRes, IGetSupermarketsInventoryModifier } from './location/location.interface';
import { IGrpcGetAvailablePickersReq, IGrpcGetAvailablePickersRes } from './picker.interface';
import { IGrpcGetAvailablePackersReq, IGrpcGetAvailablePackersRes } from './packer.interface';
import { IGrpcGetDriversReq, IGrpcGetDriversRes } from './driver.interface';
import {
  IGrpcListDispatchJobReq,
  IGrpcListDispatchJobRes,
  IGrpcOrderDetailsInDispatchJobItemReq,
  IGrpcOrderDetailsInDispatchJobItemRes,
  IGrpcValidateOperationalEmployeeReq,
  IGrpcValidateOperationalEmployeeRes
} from './fulfillment/dispatch-job.interface';
import { IGrpcGetTaxationByCountryIdReq, IGrpcGetTaxationByCountryIdRes } from './product/taxation.interface';
import {
  IGrpcCalculateOrderRefundReq,
  IGrpcCalculateOrderRefundRes,
  IGrpcProcessCheckoutRefundReq,
  IGrpcProcessOrderRefundReq,
  IInitiateOrderPaymentReq,
  IInitiateOrderPaymentRes,
  IInitiateOrderPaymentRefundReq
} from './order/order-payment.interface';
import { IGrpcUserAddressUpdateRequest, IGrpcUserAddressUpdateResponse } from './location/address.interface';
import { IGrpcLoyaltyRegisterUserReq } from './loyalty-management/loyalty-management-system.interface';
import {
  IGrpcGetPromotionActionReq,
  IGrpcGetPromotionActionRes,
  IGrpcGetPromotionsReq,
  IGrpcGetPromotionsRes
} from './promotion.interface';
import {
  IGrpcGetUserReferralDetailsReq,
  IGrpcGetUserReferralDetailsRes,
  IGrpcUpdateReferralReq
} from './referral.interface';

export interface IGrpcException {
  code: number;
  details: string;
}

export interface IUserService {
  deliveryTypeList(emptyRequest?: IKeyMapping): Observable<IGrpcDeliveryType>;
  deliveryOptionList(emptyRequest?: IKeyMapping): Observable<IGrpcDeliveryOption>;
  countryFindById(country: ICountry): Observable<ICountryGrpc>;
  countryFindByIds(country: IKeyMapping<number[]>): Observable<IGrpcCountry>;
  countryFindByName(country: ICountry): Observable<IGrpcCountry>;
  getProductDetails(request: IGrpcProductDetailRequest): Observable<IGrpcProductDetailResponse>;
  addOperationalEmployeeActivityLog(
    request: IGrpcOperationalEmployeeLogRequest
  ): Observable<IGrpcOperationalEmployeeLogResponse>;
  getOrderDetails(request: IGrpcOrderRequest): Observable<IGrpcOrderDetailResponse>;
  getOrderSummaryDetails(request: IGrpcOrderSummaryRequest): Observable<IGrpcOrderSummaryDetailResponse>;
  validateOrderRequestUserApi(request: IGrpcAddOrderUserRequest): Observable<IGrpcAddOrderUserResponse>;
  getOrderDetailsForPromotions(
    request: IGrpcOrderDetailsForPromotionsUserReq
  ): Observable<IGrpcOrderDetailsForPromotionsUserRes>;
  getEmployeeSupermarkets(request: IGrpcEmployeeSupermarket): Observable<IGrpcSupermarket>;
  validateEmployeeSupermarketById(
    request: IGrpcValidateEmployeeSupermarket
  ): Observable<IGrpcValidateEmployeeSupermarketResponse>;
  driverFindByIds(driver: IKeyMapping<number[]>): Observable<IGrpcEmployee>;
  updateVehicleDrivers(request: IGrpcVehicleDriverUpdateRequest): Observable<IGrpcVehicleDriverUpdateResponse>;
  getVehicleDetails(request: IGrpcVehicleDetailsRequest): Observable<IGrpcVehicleDetailsResponse>;
  deleteVehicleDrivers(request: IGrpcVehicleDriverDeleteRequest): Observable<IGrpcVehicleDriverUpdateResponse>;
  validateAssinedTo(request: IUserGrpcValidateAssignedTo): Observable<IUserGrpcValidateAssignedToResponse>;
  updateEmployeeAvailability(request: IGrpcUpdateAvailabilityReq): Observable<IGrpcUpdateAvailabilityRes>;
  employeeFindByIds(employee: IKeyMapping<number[]>): Observable<IGrpcEmployee>;
  userFindByName(user: IGrpcUserNameReq): Observable<IGrpcUsers>;
  getSupermarketById(request: IGrpcGetSupermarketByIdReq): Observable<IGrpcGetSupermarketByIdRes | null>;
  userFindById(user: IGrpcUserIdReq): Observable<IGrpcUser>;
  saveCheckoutCard(request: ICheckoutCard): Observable<IGrpcCommonSuccessRes>;
  getLanguage(request: IGrpcLanguageReq): Observable<ILanguage>;
  getOrderPickingShipment(request: IGrpcGetOrderPickingShipmentReq): Observable<IGrpcGetOrderPickingShipmentRes>;
  listOrderPickingBatch(request: IGrpcListOrderPickingBatchReq): Observable<IGrpcListOrderPickingBatchRes>;
  validatePickerAndGetSupermarket(
    request: IGrpcOrderPickingGetSupermarketReq
  ): Observable<IGrpcOrderPickingGetSupermarketRes>;
  listOrderPickingProducts(
    request: IUserGrpcListOrderPickingProductsReq
  ): Observable<IUserGrpcListOrderPickingProductsRes>;
  updateUserMobileNumber(request: IGrpcUpdateUserMobileNumberReq): Observable<IGrpcUpdateUserMobileNumberRes>;
  checkMobileNumber(request: IGrpcCheckMobileNumberReq): Observable<IGrpcCheckMobileNumberRes>;
  getUser(request: IGrpcGetUserReq): Observable<IGrpcGetUserRes>;
  getSupermarketAndCountryTimeZoneById(
    request: IGrpcGetSupermarketByIdReq
  ): Observable<IGrpcGetSupermarketByIdRes | null>;
  getCountryWithCurrencyByIds(country: IKeyMapping<number[]>): Observable<IGrpcCountry>;
  getAvailablePickers(request: IGrpcGetAvailablePickersReq): Observable<IGrpcGetAvailablePickersRes>;
  getPaymentAndAddressDetails(request: IGrpcGetPaymentAndAddressReq): Observable<IGrpcGetPaymentAndAddressRes>;
  getAvailablePackers(request: IGrpcGetAvailablePackersReq): Observable<IGrpcGetAvailablePackersRes>;
  getDriverDetails(request: IGrpcGetDriversReq): Observable<IGrpcGetDriversRes>;
  listDispatchJobs(request: IGrpcListDispatchJobReq): Observable<IGrpcListDispatchJobRes>;
  validateOperationalEmployee(
    request: IGrpcValidateOperationalEmployeeReq
  ): Observable<IGrpcValidateOperationalEmployeeRes>;
  cancelOrder(request: IGrpcCancelOrderReq): Observable<IGrpcCancelOrderRes>;
  processOrderRefund(request: IGrpcProcessOrderRefundReq): Observable<IUser>;
  getTimeSlot(request: IGrpcGetTimeSlotReq): Observable<IGrpcGetTimeSlotRes>;
  listOperationalEmployee(request: IGrpcListOperationalEmployeeReq): Observable<IGrpcListOperationalEmployeeRes>;
  completeOrderPickingBatch(
    request: IUserGrpcCompleteOrderPickingBatchReq
  ): Observable<IUserGrpcCompleteOrderPickingBatchRes>;
  getOrderDetailsInDispatchJobItem(
    request: IGrpcOrderDetailsInDispatchJobItemReq
  ): Observable<IGrpcOrderDetailsInDispatchJobItemRes>;
  initiateOrderPickingPayment(request: IGrpcInitiateOrderPickingPaymentReq): Observable<ICheckoutCard>;
  getSupermarketLocationDriverAndPayment(
    request: IGrpcGetSupermarketDetailsDriverAndPaymentReq
  ): Observable<IGrpcGetSupermarketDetailsDriverAndPaymentRes>;
  updateUserAddress(request: IGrpcUserAddressUpdateRequest): Observable<IGrpcUserAddressUpdateResponse>;
  getLanguagesByIds(request: IGrpcGetLanguagesByIdsReq): Observable<IGrpcGetLanguagesByIdsRes>;
  getOnlinePickers(request: IGrpcGetAvailablePickersReq): Observable<IGrpcGetAvailablePickersRes>;
  getPaymentOptions(emptyRequest?: IKeyMapping): Observable<IGrpcGetPaymentOptionsRes>;
  getCheckoutCardDetail(request: IGrpcGetCheckoutCardDetailReq): Observable<IGrpcGetCheckoutCardDetailRes>;
  findSupermarketByName(supermarket: IGrpcUserNameReq): Observable<IGrpcGetSupermarketName>;
  getDriverVehicleId(request: IGrpcGetVehicleIdReq): Observable<IGrpcGetVehicleIdRes>;
  getUserReferralDetails(request: IGrpcGetUserReferralDetailsReq): Observable<IGrpcGetUserReferralDetailsRes>;
  updateReferral(request: IGrpcUpdateReferralReq): Observable<void>;
}

export interface IProductService {
  validateOrderRequestProductApi(request: IGrpcAddOrderProductRequest): Observable<IGrpcAddOrderProductResponse>;
  getOrderDetailsForPromotions(
    request: IGrpcOrderDetailsForPromotionsProductReq
  ): Observable<IGrpcOrderDetailsForPromotionsProductRes>;
  getOrderProducts(request: IGrpcGetOrderProductRequest): Observable<IGrpcGetOrderProductResponse>;
  getCustomerOrderProducts(request: IGrpcGetOrderProductRequest): Observable<IGrpcGetCustomerOrderProductRes>;
  supplierFindByIds(supplier: IKeyMapping<number[]>): Observable<IGrpcSupplier>;
  viewPurchaseOrder(request: IGrpcViewPurchaseOrderReq): Observable<IGrpcViewPurchaseOrderResponse>;
  getProductVariants(request: IGrpcProductVariant): Observable<IGrpcProductVariantResponse>;
  getPutaway(request: IGrpcGetPutawayProductReq): Observable<IGrpcGetPutawayProductRes>;
  getProductbyName(request: IGrpcGetProductNameReq): Observable<IGrpcGetProductsRes>;
  productFindByIds(product: IKeyMapping<number[]>): Observable<IGrpcGetProductsRes>;
  updateStockInInventoryModifiers(request: IGrpcUpdateInventoryModifierStock): Observable<IKeyMapping<boolean>>;
  getProducts(product: IKeyMapping<number[]>): Observable<IGrpcGetProductsRes>;
  getProductBarcodes(request: IGrpcGetProductBarcodesReq): Observable<IGrpcProductBarcodeRes>;
  getProductsBySupermarkets(supermarkets: IKeyMapping<number[]>): Observable<IGrpcGetProductsRes>;
  getProductsBaseList(product: IKeyMapping<number[]>): Observable<IGrpcGetProductsRes>;
  getProductsBaseListBySupermarkets(supermarkets: IKeyMapping<number[]>): Observable<IGrpcGetProductsRes>;
  getInventoryModifierProducts(
    request: IGrpcGetInventoryModifierStockReq
  ): Observable<IGrpcGetInventoryModifierStockRes>;
  getOrderProductsForPicker(request: IGrpcGetOrderProductRequest): Observable<IGrpcGetOrderProductResponse>;
  getCreateOrderPickingProducts(request: IGrpcCreateOrderPickingReq): Observable<IGrpcCreateOrderPickingRes>;
  listOrderPickingProducts(request: IGrpcListOrderPickingProductsReq): Observable<IGrpcListOrderPickingProductsRes>;
  listAdminOrderPickingProducts(
    request: IGrpcListAdminOrderPickingProductsReq
  ): Observable<IGrpcListAdminOrderPickingProductsRes>;
  getSupermarketsInventoryModifier(
    request: IGetSupermarketsInventoryModifier
  ): Observable<GetSupermarketsInventoryModifierRes>;
  getProductDetails(product: IKeyMapping<number[]>): Observable<IGrpcGetProductsRes>;
  listOrderPickingJobProducts(
    request: IGrpcListOrderPickingJobProductsReq
  ): Observable<IGrpcListOrderPickingJobProductsRes>;
  getTaxationByCountryId(request: IGrpcGetTaxationByCountryIdReq): Observable<IGrpcGetTaxationByCountryIdRes>;
  calculateOrderRefund(request: IGrpcCalculateOrderRefundReq): Observable<IGrpcCalculateOrderRefundRes>;
  registerUserToLms(request: IGrpcLoyaltyRegisterUserReq): Observable<void>;
  getPromotions(request: IGrpcGetPromotionsReq): Observable<IGrpcGetPromotionsRes>;
  getPromotionAction(request: IGrpcGetPromotionActionReq): Observable<IGrpcGetPromotionActionRes>;
  getLmsProducts(request: IGrpcGetLmsProductsReq): Observable<IGrpcGetLmsProductsRes>;
  getProductWithInventoryModifiers(
    request: IGrpcGetProductWithInventoryModifiersReq
  ): Observable<IGrpcGetProductWithInventoryModifiersRes>;
  getProductsWithTrackStock(
    request: IGrpcGetProductsWithTrackStockReq
  ): Observable<IGrpcGetProductsWithTrackStockRes>;
}

export interface IAuthService {
  createUser(request: IGrpcCreateUserReq): Observable<IGrpcCreateUserRes>;
  sendPushNotification(request: IGrpcSendPushNotificationReq): Observable<IGrpcCommonSuccessRes>;
  updateAuthUser(request: IGrpcUpdateAuthUserReq): Observable<IGrpcUpdateAuthUserRes>;
  getAuthUserDetail(request: IGrpcGetAuthUserDetailReq): Observable<IGrpcGetAuthUserDetailRes>;
  toggleUserBlacklistedStatus(request: IGrpcUserBlacklistReq): Observable<void>;
  deleteUser(request: IGrpcUserIdReq): Observable<void>;
}

export interface IOrderService {
  getStockMasterProducts(request: IGrpcGetStockMasterStockReq): Observable<IGrpcStockMasterStockRes>;
  getAvailableStoresOrderShipments(request: IGrpcGetOrderShipmentsReq): Observable<IGrpcGetOrderShipmentsRes>;
  saveOrderByPaymentId(request: ISaveOrderByPaymentIdReq): Observable<ISaveOrderByPaymentIdRes>;
  checkoutPaymentWebhookEvent(request: ICheckoutWebhookEventData): Observable<void>;
  getStockLocations(request: IGrpcGetStockMasterStockReq): Observable<IGrpcStockLocationRes>;
  getOrdersByUserIds(request: IKeyMapping<number[]>): Observable<IGrpcGetOrdersByUserIdsRes>;
  getConsumerOrders(request: IGrpcGetConsumerOrdersReq): Observable<IGrpcGetConsumerOrdersRes>;
  unassignOrderPickingBatchesOrBinsAndProducts(
    request: IGrpcUnassignOrderPickingBatchesOrBinsAndProductsReq
  ): Observable<void>;
  getUserOrderStats(request: IGrpcGetConsumerOrdersReq): Observable<IGrpcUserOrderStatsRes>;
  processCheckoutRefund(request: IGrpcProcessCheckoutRefundReq): Observable<void>;
  addLocationAndStockLocation(
    request: IGrpcAddLocationAndStockLocationReq
  ): Observable<IGrpcAddLocationAndStockLocationRes>;
  checkLocationsExists(request: IGrpcCheckLocationsExistsReq): Observable<IGrpcCheckLocationsExistsRes>;
  getOrderedProductsLastWeekPurchase(request?: IKeyMapping): Observable<IGrpcGetOrderedProductLastWeekPurchaseRes>;
}

export interface IPaymentGatewayService {
  initiateOrderPayment(request: IInitiateOrderPaymentReq): Observable<IInitiateOrderPaymentRes>;
  initiateOrderPaymentRefund(request: IInitiateOrderPaymentRefundReq): Observable<ICheckoutPaymentRefundResponse>;
}
