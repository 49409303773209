export default {
  MOBILE_OTP_VERIFICATION_MESSAGE: 'Hello {user_name}, your mobile verification OTP is {otp}',
  ORDER_REFUND_CONFIRMATION_MESSAGE:
    'Dear Customer, A {refund_type} refund of {currency} {refund_amount} has been issued to your card ending with {card_last_4_digit} on {refund_time} due to wrong/missing items in your order {order_id}. Thank you for using {company_name}',
  DISPATCH_DELIVERY_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'dispatch_job_delivery_notification_title',
    BODY: 'dispatch_job_delivery_notification_body'
  },
  ORDER_SHIPMENT_DELIVERY_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'order_shipment_delivery_notification_title',
    BODY: 'order_shipment_delivery_notification_body'
  },
  ORDER_PICKING_JOB_ASSIGN_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'order_picking_job_assign_notification_title',
    BODY: 'order_picking_job_assign_notification_body',
    ROUTES: '/picking-order-list-view'
  },
  ORDER_PICKING_SHIPMENT_PAYMENT_FAILED_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'order_picking_shipment_payment_failed_notification_title',
    BODY: 'order_picking_shipment_payment_failed_notification_body'
  },
  DELIVERY_BATCH_ASSIGNMENT_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'delivery_batch_assignment_notification_title',
    BODY: 'delivery_batch_assignment_notification_body',
    ROUTES: '/delivery-view'
  },
  STAGGER_JOB_ASSIGNMENT_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'stagger_job_assignment_notification_title',
    BODY: 'stagger_job_assignment_notification_body',
    ROUTES: '/dispatch-view'
  },
  PUTAWAY_ASSIGNMENT_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'putaway_assignment_notification_title',
    BODY: 'putaway_assignment_notification_body'
  },
  DELIVERY_BATCH_CANCEL_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'delivery_batch_assignment_cancel_notification_title',
    BODY: 'delivery_batch_assignment_cancel_notification_body'
  },
  PUTAWAY_ASSIGNMENT_CANCEL_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'putaway_assignment_cancel_notification_title',
    BODY: 'putaway_assignment_cancel_notification_body',
    ROUTES: '/operational-put-away-list-screen'
  },
  ORDER_TOTAL_CHANGED_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'order_total_changed_notification_title',
    BODY: 'order_total_changed_notification_body'
  },
  ORDER_SHIPMENT_READY_TO_PICK_PUSH_NOTIFICATION_CONTENT_KEYS: {
    TITLE: 'order_shipment_ready_to_pick_notification_title',
    BODY: 'order_shipment_ready_to_pick_notification_body'
  }
};
