import { IBaseAudit } from '../auditable.interface';
import { ICommonOption } from '../common.interface';

export interface IInvoice extends IBaseAudit {
  public_id: string;
  shipment_id: number;
  supermarket_id: number;
  type: InvoiceTypeEnum;
  total_tax: number;
  total_amount: number;
  total_allowance: number;
  invoice_pdf_url: string;
  invoice_zatca?: IInvoiceZatca;
  supermarket_name?: string;
}

export enum InvoiceTypeEnum {
  MAIN = 'main',
  RETURN = 'return'
}

export interface IInvoiceZatca extends IBaseAudit {
  invoice_id: number;
  total_tax: number;
  total_amount: number;
  signed_xml_url: string;
  invoice_hash: string;
  qr_code: string;
  // uuid:string;
  report_response?: string;
  reported_at?: Date;
  status: InvoiceZatcaStatusEnum;
}

export interface IInvoiceGeneratedZatca {
  signed_invoice_string: string;
  invoice_hash: string;
  qr: string;
}

export interface IInvoiceListData {
  page: number;
  id: string;
  public_id: string;
  shipment_id: string;
  supermarket_id: number;
  type: string;
  created_at: string;
}

export interface IInvoiceListPayload extends IInvoiceListData {
  [index: string]: string | number;
}

export enum InvoiceGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  PUBLIC_ID = 'public_id',
  SHIPMENT_ID = 'shipment_id',
  SUPERMARKET_ID = 'supermarket_id',
  TYPE = 'type',
  CREATED_AT = 'created_at'
}

export const invoiceTypeOptions: ICommonOption<InvoiceTypeEnum>[] = [
  {
    id: InvoiceTypeEnum.MAIN,
    name: 'Main'
  },
  {
    id: InvoiceTypeEnum.RETURN,
    name: 'Return'
  }
];

export enum InvoiceZatcaStatusEnum {
  PENDING = 'pending',
  REPORTED = 'reported',
  FAILED = 'failed'
}

export const OrderInvoiceSelect = {
  id: true,
  shipment_id: true,
  public_id: true,
  supermarket_id: true,
  type: true,
  total_tax: true,
  total_amount: true,
  created_at: true,
  invoice_pdf_url: true,
  invoice_zatca: {
    id: true,
    invoice_hash: true,
    signed_xml_url: true,
    qr_code: true,
    report_response: true,
    reported_at: true,
    status: true
  }
};
