export default {
  APP_LOGO_PATH: 'assets/logo.png',
  APP_SHORT_LOGO_PATH: 'assets/short-logo.png',
  EMAIL_ASSETS_PATH: 'assets/email/',
  COMMON_TEMP_PATH: 'common/',
  PRODUCT_IMAGE: 'images/products/',
  PRODUCT_VARIANT_IMAGE: 'product/variant/image/',
  ZONE_BARCODE_TEMP_PATH: 'zone/barcode/',
  PDF_PURCHASE_ORDER_TEMP_PATH: 'pdf/purchase-order/',
  INVOICE_IMAGE: 'invoice/',
  PUTAWAY_PRODUCT_REPORT_IMAGE: 'putaway/product/report/image/',
  PICKING_PRODUCT_REPORT_IMAGE: 'picking/product/report/image/',
  ADHOC_PRODUCT_REPORT_IMAGE: 'adhoc/product/report/image/',
  ORDER_SHIPMENT_REPORT_IMAGE: 'order/shipments/report/image/',
  VERIFIED_ADDRESS_IMAGE: 'verified-address/image/',
  FEEDBACK_IMAGE: 'feedback/image',
  VEHICLE_HEALTH_EVIDENCE_IMAGE: 'vehicle-health-evidence/image',
  ORDER_INVOICE: 'order/invoices/',
  PDF_PRINT_LABEL_TEMP_PATH: 'print-labels/pdf/',
  DISPATCH_JOB_RETURNED_ITEMS_IMAGE: 'dispatch-job/returned-items/image/'
};
