import { Observable } from 'rxjs';

import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';

import { environment } from '../../../environments/environment';

@Injectable()
export class LocalServerInterceptor implements HttpInterceptor {
  public intercept<T>(request: HttpRequest<T>, next: HttpHandler): Observable<HttpEvent<Request>> {
    if (environment.name !== 'local') return next.handle(request);

    const replacedUrl: string = this.fixUrl(request.url);
    const clonedRequest: HttpRequest<T> = request.clone({ url: replacedUrl });

    return next.handle(clonedRequest);
  }

  private fixUrl(url: string): string {
    const urlObj = new URL(url);
    const microserivce: string = urlObj.pathname
      .split('/')
      .filter((part) => part)
      .slice(1, 2)
      .join('');
    return `${this.getMicroservicePath(microserivce)}${urlObj.pathname}`;
  }

  private getMicroservicePath(basePath: string): string {
    const paths = {
      // userMs: [
      //   'users',
      //   'address',
      //   'aisle-shelves',
      //   'checkout-cards',
      //   'cities',
      //   'countries',
      //   'currencies',
      //   'customers',
      //   'delivery-options',
      //   'delivery-types',
      //   'districts',
      //   'employees',
      //   'feedbacks',
      //   'holidays',
      //   'languages',
      //   'locations',
      //   'operational-employees',
      //   'payment-options',
      //   'permissions',
      //   'roles',
      //   'super-markets',
      //   'time-slots',
      //   'warehouses'
      // ],
      authMs: ['device-token', 'auth'],
      coreMs: ['core', 'ads', 'translations'],
      productMs: [
        'products',
        'brands',
        'bulk-upload',
        'cart',
        'categories',
        'cuisines',
        'inventory_modifiers',
        'manufacturers',
        'offline-products',
        'option-types',
        'product-variants',
        'promotions',
        'recipes',
        'bin-categories',
        'taxations',
        'suppliers',
        'taxon-group',
        'taxonomies',
        'wishlists'
      ],
      orderMs: [
        'delivery-batch',
        'drivers',
        'delivery-zones',
        'hold-reasons',
        'orders',
        'shipments',
        'order-cancellation-reasons',
        'order-picking',
        'order-shipment-reports',
        'packers',
        'pickers',
        'stagger-job',
        'vehicle-managements',
        'wms-bins',
        'wms-locations',
        'wms-purchase-orders',
        'wms-putaway',
        'wms-receiving',
        'wms-putaway',
        'wms-stocks',
        'wms-zones',
        'gift-cards',
        'wms-product-reports',
        'store-credit',
        'lms-transaction',
        'invoice',
        'inventory-officer'
      ]
    };
    let baseApiUrl = 'http://localhost:3333'; // user microservice
    if (paths.authMs.includes(basePath)) baseApiUrl = 'http://localhost:3334'; // auth microservice
    else if (paths.productMs.includes(basePath)) baseApiUrl = 'http://localhost:3335'; // product microservice
    else if (paths.coreMs.includes(basePath)) baseApiUrl = 'http://localhost:3336'; // core microservice
    else if (paths.orderMs.includes(basePath)) baseApiUrl = 'http://localhost:3339'; // order microservice
    return baseApiUrl;
  }
}
