export interface IInventoryOfficerJobsCount {
  inventory_reports: number;
  inspect_returns: number;
}

export interface IUnprocessedDjReturnsCount {
  count: number;
}

export const unprocessedDispatchJobReturnsSelect = {
  dispatch_job_id: true,
  product_id: true,
  fit_quantity: true,
  return_location_id: true,
  return_location: {
    id: true,
    supermarket_id: true,
    zone_id: true,
    aisle: true,
    bay: true,
    level: true,
    division: true,
    position: true,
    zone: {
      id: true,
      name: true
    }
  }
};
