import { IBaseAudit } from '../auditable.interface';
import { IEmployeeData } from '../employee.interface';
import { BinCategoryEnum } from '../product/bin-category.interface';
import { IProduct } from '../product/product.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { IWmsBin } from '../warehouse-management/wms-bin.interface';
import { IWmsLocation, wmsLocationBaseListSelect } from '../warehouse-management/wms-location.interface';
import { IOrderProduct } from './order-product.interface';
import { IOrderShipment } from './order-shipment.interface';

export interface IOrderPickingShipmentBins extends IBaseAudit {
  order_shipment_id: number;
  bin_id: number;
  staging_location_id: number;
  dispatch_location_id?: number;
  assigned_to?: number;
  assigned_at?: Date;
  started_at?: Date;
  picked_at?: Date;
  dropped_at?: Date;
  products_count?: number;
  is_returned_by_driver?: boolean;
  order_picking_shipment_bin_products?: IOrderPickingShipmentBinProducts[];
  order_shipment?: IOrderShipment;
  staging_location?: IWmsLocation;
  dispatch_location?: IWmsLocation;
  bin?: IWmsBin;
  supermarket?: ISuperMarket;
  packer?: IEmployeeData;
  label_pdf_url?: string;
  print_job_ref?: string;
}

export interface IOrderPickingShipmentProducts extends IBaseAudit {
  order_shipment_id: number;
  order_product_id: number;
  quantity: number;
  staging_location_id: number;
  bin_category: BinCategoryEnum;
  dispatch_location_id?: number;
  assigned_to?: number;
  assigned_at?: Date;
  started_at?: Date;
  picked_at?: Date;
  dropped_at?: Date;
  order_shipment?: IOrderShipment;
  staging_location?: IWmsLocation;
  dispatch_location?: IWmsLocation;
  order_product?: IOrderProduct;
  supermarket?: ISuperMarket;
  packer?: IEmployeeData;
  product?: Partial<IProduct>;
  label_pdf_url?: string;
  print_job_ref?: string;
}

export interface IOrderPickingShipmentBinProducts extends IBaseAudit {
  order_picking_shipment_bin_id?: number;
  order_product_id: number;
  quantity: number;
  staging_location_id?: number;
  bin_category?: BinCategoryEnum;
  order_picking_shipment_bin?: IOrderPickingShipmentBins;
  staging_location?: IWmsLocation;
  order_product?: IOrderProduct;
  product?: Partial<IProduct>;
}

export interface IAssignStaggerJobBinsAndProducts {
  order_picking_item_id: number;
  order_shipment_id: number;
  type: StaggerJobItemsTypeEnum;
  supermarket_id: number;
  dispatch_location_id: number;
  assigned_to?: number;
  assigned_at?: Date;
}

export interface IOrderPickingPrintLabel {
  id: number;
  orderId?: string;
  deliveryTime?: string;
  counter?: string;
  loadNumber?: string;
  barcode?: string;
  deliveryDate?: string;
  type?: StaggerJobItemsTypeEnum;
}

export interface IPackersStaggerJob {
  packer_id: number;
  assigned_count: number;
  last_dispatch_location_id: number;
}

export interface IPackersJobCount {
  packer: IEmployeeData;
  jobCount: number;
}

export interface IMissingItems {
  missingBins: number[];
  missingProducts: number[];
}

export interface IOrderPickingShipmentDetail {
  shipmentId: number;
  supermarketId: number;
  printerId: number;
  orderId: number;
}

export interface IBinCategories {
  [binCategory: string]: number[];
}

export interface IGroupedByShipment {
  [key: string]: {
    ids: number[];
  };
}

export interface Item {
  shipment_id: number;
  id: number;
  [key: string]: number;
}

export interface IProductCounter {
  id: number;
  counter: string;
}

export interface IPrintNodeReq {
  printerId: number;
  title: string;
  content: string;
  source: string;
  qty: number;
  contentType: string;
  options: {
    paper: string;
    pages: string;
  };
}

export interface IPrintJobState {
  age: number;
  clientVersion: string;
  createTimestamp: string;
  data: unknown;
  message: string;
  printJobId: string;
  state: 'new' | 'sent_to_client' | 'queued' | 'in_progress' | 'done';
}

export interface IPrintJobStatesResponse {
  data: IPrintJobState[];
  code: number;
}

export interface IPrinterStatusResponse {
  state: string;
}

export enum StaggerJobItemsTypeEnum {
  BIN = 'bin',
  PRODUCT = 'product'
}

export enum ListAssignedStaggerJobsStatus {
  PENDING = 'pending',
  COMPLETE = 'complete'
}

interface IStagingDispatchBinListData {
  page: number;
  id: string;
  order_id: number;
  supermarket_id: number;
  bin_id: number;
  bin_type: string;
  products_count: number;
  assigned_to: number;
  status: string;
}

export interface IStagingDispatchBinListPayload extends IStagingDispatchBinListData {
  [index: string]: string | number;
}

export enum StagingDispatchBinGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  ORDER_ID = 'order_id',
  SUPERMARKET_ID = 'supermarket_id',
  PRODUCT_COUNT = 'products_count',
  BIN_ID = 'bin_id',
  BIN_TYPE = 'bin_type',
  STATUS = 'status',
  ASSIGNED_TO = 'assigned_to'
}

interface IStagingDispatchProductListData {
  page: number;
  id: string;
  order_id: number;
  supermarket_id: number;
  product_name: string;
  quantity: number;
  bin_category: string;
  status: string;
  assigned_to: number;
}

export interface IStagingDispatchProductListPayload extends IStagingDispatchProductListData {
  [index: string]: string | number;
}

export interface IOrderPickingShipmentItemsCount {
  bins: number;
  oversized: number;
}

export interface IStaggerJobReassignPayload {
  assigned_to: number;
}

export enum StagingDispatchProductGridFilterEnum {
  PAGE = 'page',
  ID = 'id',
  ORDER_ID = 'order_id',
  SUPERMARKET_ID = 'supermarket_id',
  PRODUCT_NAME = 'product_name',
  QUANTITY = 'quantity',
  BIN_CATEGORY = 'bin_category',
  STATUS = 'status',
  ASSIGNED_TO = 'assigned_to'
}

export const listStaggerJobBinsSelect = {
  id: true,
  bin_id: true,
  picked_at: true,
  dropped_at: true,
  label_pdf_url: true,
  staging_location: { ...wmsLocationBaseListSelect },
  dispatch_location: { ...wmsLocationBaseListSelect },
  order_shipment_id: true,
  order_shipment: {
    order_id: true,
    order: {
      currency: true,
      delivery_option_id: true
    }
  },
  order_picking_shipment_bin_products: {
    id: true,
    order_product_id: true,
    staging_location: { ...wmsLocationBaseListSelect },
    quantity: true,
    bin_category: true,
    order_product: {
      product_id: true,
      product_variant_id: true,
      price: true,
      action_price: true
    }
  }
};

export const listStaggerJobProductsSelect = {
  id: true,
  order_product_id: true,
  quantity: true,
  bin_category: true,
  picked_at: true,
  dropped_at: true,
  label_pdf_url: true,
  staging_location: { ...wmsLocationBaseListSelect },
  dispatch_location: { ...wmsLocationBaseListSelect },
  order_shipment_id: true,
  order_shipment: {
    order_id: true,
    order: { currency: true }
  },
  order_product: {
    product_id: true,
    product_variant_id: true,
    price: true,
    action_price: true
  }
};

export const listStaggerJobBinSelect = {
  id: true,
  order_shipment_id: true,
  bin_id: true,
  dispatch_location_id: true,
  assigned_to: true,
  assigned_at: true,
  picked_at: true,
  dropped_at: true,
  label_pdf_url: true,
  print_job_ref: true,
  order_picking_shipment_bin_products: { id: true },
  order_shipment: {
    id: true,
    order_id: true,
    supermarket_id: true
  },
  dispatch_location: { ...wmsLocationBaseListSelect },
  bin: {
    id: true,
    type: true
  }
};

export const listStaggerJobProductSelect = {
  id: true,
  order_shipment_id: true,
  order_product_id: true,
  quantity: true,
  bin_category: true,
  dispatch_location_id: true,
  assigned_to: true,
  assigned_at: true,
  picked_at: true,
  dropped_at: true,
  label_pdf_url: true,
  print_job_ref: true,
  order_product: {
    id: true,
    product_id: true
  },
  order_shipment: {
    id: true,
    order_id: true,
    supermarket_id: true
  },
  dispatch_location: { ...wmsLocationBaseListSelect }
};
