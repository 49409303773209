import { IBaseAudit } from './auditable.interface';
import { IRole } from './role.interface';
import { BaseSelect } from './common.interface';

export interface IPermission extends IBaseAudit {
  name?: string;
  menu: string;
  menu_order: number;
  module: string;
  action: PermissionActionEnum;
  order?: number;
  roles?: IRole[];
}

export interface IPermissionListItem extends IPermission {
  id: number;
}

export interface IPermissionMenu {
  name: string;
  sub_menu: IPermissionSubMenu[];
}

export interface IPermissionSubMenu {
  name: string;
  module: string;
  actions: IAction[];
}

export interface IAction {
  id: number;
  module: string;
  action?: PermissionActionEnum;
}

export const permissionListSelect = {
  ...BaseSelect,
  menu: true,
  module: true,
  action: true
};

export interface IPermissionToken {
  module: string;
  action: PermissionActionEnum;
}

export const permissionTokenSelect = {
  module: true,
  action: true
};

export enum PermissionActionEnum {
  READ = 'read',
  CREATE = 'create',
  UPDATE = 'update',
  DELETE = 'delete',
  EXPORT = 'export',
  BULK_UPLOAD = 'bulk_upload',
  CUSTOMER_VIEW = 'customer_view',
  ORDER_CANCEL = 'order_cancel',
  BLACKLIST_USER = 'blacklist_user',
  DOWNLOAD = 'download'
}

export enum PermissionModuleEnum {
  CUSTOMER = 'customer',
  EMPLOYEE = 'employee',
  SUPER_MARKET = 'super-market',
  COUNTRY = 'country',
  CITY = 'city',
  DISTRICT = 'district',
  ROLE = 'role',
  WAREHOUSE = 'warehouse',
  SUPPLIER = 'supplier',
  MANUFACTURER = 'manufacturer',
  BIN_CATEGORY = 'bin-category',
  TAXATION = 'taxation',
  PRODUCT = 'product',
  BRAND = 'brand',
  TAXONOMY = 'taxonomy',
  OPTION_TYPE = 'option-type',
  TAXON_GROUP = 'taxon-group',
  DELIVERY_CAPACITY_MANAGEMENT = 'delivery-capacity-management',
  HOLIDAY = 'holiday',
  PROMOTION = 'promotion',
  ORDER = 'order',
  ORDER_CANCELLATION_REASON = 'order-cancellation-reason',
  CUISINE = 'cuisine',
  RECIPE = 'recipe',
  WMS_BIN = 'wms-bin',
  WMS_ZONE = 'wms-zone',
  WMS_LOCATION = 'wms-location',
  WMS_PURCHASE_ORDER = 'wms-purchase-order',
  WMS_RECEIVING = 'wms-receiving',
  WMS_PUTAWAY = 'wms-putaway',
  WMS_STOCK_MANAGEMENT = 'wms-stock-management',
  ORDER_PICKING_ASSIGNMENT = 'order-picking-assignment',
  VEHICLE_MANAGEMENT = 'vehicle-management',
  DELIVERY_ZONE = 'delivery-zone',
  STAGING_DISPATCH_ASSIGNMENT = 'staging-dispatch-assignment',
  DELIVERY_BATCH = 'delivery-batch',
  SHIPMENT = 'shipment',
  REFUND = 'refund',
  SUPERMARKET_CAPACITY = 'supermarket-capacity',
  REPORTED_PRODUCT = 'reported-product',
  DELIVERY_REGION = 'delivery-region',
  BULK_PRODUCT_UPLOAD = 'bulk-product-upload',
  BULK_PRODUCT_UPDATE = 'bulk-product-update',
  BULK_METADATA = 'bulk-metadata',
  BULK_INVENTORY_MODIFIERS = 'bulk-inventory-modifiers',
  BULK_NUTRITIONAL_FACTS = 'bulk-nutritional-facts',
  BULK_BUNDLE_PRODUCTS = 'bulk-bundle-products',
  BULK_PRODUCT_TAXONS = 'bulk-product-taxons',
  BULK_OFFLINE_PRODUCTS = 'bulk-offline-products',
  BULK_PRODUCT_DEEPLINKS = 'bulk-product-deeplinks',
  BULK_PRODUCT_LOCATION_STOCK = 'bulk-product-location-stock',
  CATEGORY = 'category',
  ORDER_GENERATOR = 'order-generator',
  INVOICE = 'invoice',
  DISPATCH_RETURN = 'dispatch-return'
}
