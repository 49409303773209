import { IIdMapping, IKeyMapping } from './key-mapping.interface';
import { IOrderProduct } from './order/order-product.interface';

export interface ICommonEmailData {
  user_name?: string;
  email?: string;
  thanks_and_regards?: string;
}

export interface INotificationCommonEmailData {
  subject?: string;
}

export interface INotificationDefaultEmailData {
  company_name: string;
  logo_url: string;
  short_logo_url: string;
  banner_url: string;
  apple_store_link: string;
  play_store_link: string;
  apple_logo_url: string;
  play_store_logo_url: string;
  facebook_link: string;
  youtube_link: string;
  instagram_link: string;
  twitter_link: string;
  snapchat_link: string;
  facebook_icon_url: string;
  youtube_icon_url: string;
  instagram_icon_url: string;
  twitter_icon_url: string;
  snapchat_icon_url: string;
  company_address: string;
  referral_bonus: string;
  unsubscribe_link: string;
  referral_link: string;
  contact_support_link: string;
  go_to_app_link: string;
  help_center_link: string;
  terms_condition_link: string;
  loyalty_point_icon_url: string;
  delivery_icon_url: string;
  delivery_comment_icon_url: string;
  tracking_status_url: string;
  refund_icon_url: string;
  vector_icon_url: string;
  trn_no?: string;
  crn_no?: string;
  company_link?: string;
  company_customer_no?: string;
}

export interface IOrderEmailTemplateData {
  order_id: string;
  cash_coupon_header: string;
  sub_total_header: string;
  delivery_date_time: string;
  delivery_fee_header: string;
  city: string;
  district: string;
  total_header: string;
  tax_content_header: string;
  order_date: string;
  invoice_date: string;
  order_no: string;
  total_delivery_charges: string;
  order_total: string;
  order_item_total: string;
  payment_method: string;
  shipping_address?: string;
  delivery_comment?: string;
  card_payment: string;
  cod_payment: number;
  currency: string;
  card_type: string;
  card_name: string;
  tax_percentage: string;
  last_digits: string;
  loyalty_points: string;
  shipments_count: string;
  products_count: string;
  delivery_charges: string;
  tax_total: string;
  promotion_label: string;
  innvoice_promotion_label_en: string;
  innvoice_promotion_label_ar: string;
  promotion_deduction_total: number;
  is_shipment_delivery_mail: boolean;
  shipment_summary_list: IOrderEmailShipmentTemplateData[];
  order_payments: IOrderEmailPaymentsCharged[];
  cancellation_reason: string;
  cancellation_comment: string;
  contact_support_content: string;
  refund_information_content_1: string;
  refund_information_content_2: string;
  refund_information_content_3: string;
  refund_information_content_4: string;
  shipment_delivered_date?: string;
  super_market_name: string;
  super_market_address: string;
  sum_total_inc_amount_words: string;
  club_member_id: string;
  sum_total_inc_amount: number;
  sum_total_exc_amount: number;
  sum_total_vat_amount: number;
  sum_delivery_charge_inc_vat: number;
  sum_sub_total: number;
}

export interface IOrderEmailPaymentsCharged {
  currency: string;
  payment_amount: number;
  payment_header: string;
}

export interface IOrderEmailShipmentTemplateData {
  order_summary_title_content: string;
  promotion_label: string;
  promotion_deduction_total: number;
  is_cancellation_mail: boolean;
  is_shipment_delivery_mail: boolean;
  order_total: string;
  order_item_total: string;
  no_shipments_content: string;
  total_header: string;
  shipment_status: string;
  shipment_status_content: string;
  cancelled_shipment?: boolean;
  shipment_summary_header: string;
  delivery_fee_header: string;
  products_count: string;
  tax_content_header: string;
  sub_total_header: string;
  sub_total_items_header: string;
  shipments_count: string;
  delivery_header: string;
  delivery_charges: string;
  delivery_comment_icon_url: string;
  delivery_icon_url: string;
  refund_icon_url: string;
  loyalty_point_icon_url: string;
  vector_icon_url: string;
  shipment_total: string;
  shipment_sub_total: string;
  currency: string;
  tax_total: string;
  shipment_delivering_date_time: string;
  products: IOrderEmailShipmentProductsTemplateData[];
}

export interface IOrderEmailShipmentProductsTemplateData {
  name: string;
  name_en: string;
  name_ar: string;
  price: string;
  qty: string;
  delivered_qty: string;
  tax_percentage: string;
  total_vat_amount: number;
  price_excl_vat: string;
  total_price_excl_vat: number;
  total_price_incl_vat: number;
  discount: number;
  is_discounted: boolean;
  barcode: string;
  currency: string;
  variant: string;
  product_image: string;
  weight: string;
  weighted: boolean;
  replacement_of?: string;
}

export interface IPushNotificationData {
  title: string;
  body?: string;
  data?: IKeyMapping;
}

export interface IGeneratePickedProducts {
  pickedProducts: IOrderProduct[];
  replacedProductsMap: IIdMapping<string>;
}

export enum NotificationIconPathEnum {
  FULL_LOGO_URL = 'images/logo/full-logo.png',
  LOGO_URL = 'images/logo/logo.png',
  EMAIL_BANNER_URL = 'images/email-template/banner.png',
  APPLE_STORE_IMAGE_URL = 'images/email-template/apple-store.png',
  PLAY_STORE_IMAGE_URL = 'images/email-template/play-store.png',
  FACEBOOK_ICON_URL = 'images/email-template/facebook.png',
  YOUTUBE_ICON_URL = 'images/email-template/youtube.png',
  INSTAGRAM_ICON_URL = 'images/email-template/instagram.png',
  TWITTER_ICON_URL = 'images/email-template/twitter.png',
  SNAPCHAT_ICON_URL = 'images/email-template/snapchat.png'
}
