import { IBaseAudit } from '../auditable.interface';
import { IDispatchJob } from './dispatch-job.interface';
import { IOrderShipment } from '../order/order-shipment.interface';
import { IWmsBin } from '../warehouse-management/wms-bin.interface';
import { IWmsLocation } from '../warehouse-management/wms-location.interface';
import { DeepPartial } from 'typeorm';
import { IProduct } from '../product/product.interface';
import { IEmployeeData } from '../employee.interface';

export interface IDispatchJobReturn extends IBaseAudit {
  dispatch_job_id: number;
  order_shipment_id: number;
  bin_id?: number;
  product_id: number;
  returned_quantity: number;
  return_location_id?: number;
  driver_id: number;
  returned_at?: Date;
  verified_by?: number;
  verified_at?: Date;
  verification_image_url?: string;
  processed_by?: number;
  processed_at?: Date;
  damaged_quantity?: number;
  fit_quantity?: number;
  product?: DeepPartial<IProduct>;
  dispatch_job?: IDispatchJob;
  order_shipment?: IOrderShipment;
  bin?: IWmsBin;
  return_location?: IWmsLocation;
  supermarket_name?: string;
  driver?: IEmployeeData;
  employee?: IEmployeeData;
  processor?: IEmployeeData;
}

export interface IDjReturnsWithBinsAndProductsCount {
  dispatch_job_id: number;
  bins: number;
  case_products: number;
}

export enum DispatchJobReturnGridFilterEnum {
  PAGE = 'page',
  BIN_ID = 'bin_id',
  BATCH_ID = 'batch_id',
  SHIPMENT_ID = 'shipment_id',
  SUPERMARKET_ID = 'supermarket_id',
  DRIVER_ID = 'driver_id',
  VERIFIED_BY = 'verified_by',
  RETURNED_AT = 'returned_at',
  PRODUCT_NAME = 'product_name'
}

export interface IDispatchJobReturnListData {
  page: number;
  batch_id: string;
  bin_id: string;
  shipment_id: string;
  supermarket_id: number;
  driver_id: number;
  verified_by: number;
  product_name: string;
  returned_at: string;
}

export interface IDispatchJobReturnListPayload extends IDispatchJobReturnListData {
  [index: string]: string | number;
}

export const listDispatchJobReturnsSelect = {
  id: true,
  dispatch_job_id: true,
  order_shipment_id: true,
  bin_id: true,
  product_id: true,
  returned_quantity: true,
  return_location: {
    supermarket_id: true,
    zone_id: true,
    aisle: true,
    position: true,
    bay: true,
    level: true,
    division: true
  }
};

export const paginatedDispatchJobReturnsSelect = {
  id: true,
  dispatch_job_id: true,
  order_shipment_id: true,
  bin_id: true,
  driver_id: true,
  product_id: true,
  returned_quantity: true,
  fit_quantity: true,
  damaged_quantity: true,
  returned_at: true,
  verified_by: true,
  verified_at: true,
  return_location_id: true,
  processed_by: true,
  processed_at: true,
  verification_image_url: true,
  dispatch_job: {
    supermarket_id: true
  },
  return_location: {
    zone_id: true,
    aisle: true,
    position: true,
    bay: true,
    level: true,
    division: true
  }
};
