import { IBaseAudit } from '../auditable.interface';
import { IOrderShipment } from './order-shipment.interface';
import { IWmsBin } from '../warehouse-management/wms-bin.interface';

export interface IShipmentReturnedItem extends IBaseAudit {
  order_shipment_id: number;
  bin_id?: number;
  product_id: number;
  returned_quantity: number;
  image_url: string;
  reason?: string;
  order_shipment?: IOrderShipment;
  bin?: IWmsBin;
}

export interface IDispatchJobShipmentReturnedItem {
  product_id: number;
  bin_id?: number;
  returned_quantity: number;
  image_url: string;
}
