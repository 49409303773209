import { IBaseAudit } from '../auditable.interface';
import { IProductVariant } from '../product/product-variant.interface';
import { IProduct } from '../product/product.interface';
import { ISuperMarket } from '../supermarket/super-market.interface';
import { IWmsLocation } from './wms-location.interface';
import { IWmsZone } from './wms-zone.interface';
import { BaseSelect } from './../common.interface';

export interface IWmsStockMaster extends IBaseAudit {
  supermarket_id: number;
  product_id: number;
  product_variant_id?: number;
  available_units: number;
  reserved_units: number;
  supermarket?: ISuperMarket;
  product?: IProduct;
  product_variant?: IProductVariant;
  stock_locations?: IWmsStockLocation[];
  version?: number;
}

export interface IWmsStockLocationData {
  supermarket_id: number;
  zone_id: number;
  product_id: number;
  product_variant_id: number;
}

export interface IWmsStockListData {
  page: number;
  supermarket_id: number;
  product_name: string;
  total_units: number;
  available_units: number;
  reserved_units: number;
}

export interface IGrpcGetStockMasterStock {
  supermarket_id: number;
  product_id: number;
  product_variant_id: number;
}

export interface IGrpcGetStockMasterStockReq {
  products: IGrpcGetStockMasterStock[];
}

export interface IGrpcStockMasterStockRes {
  stocks: Partial<IWmsStockMaster>[];
}

export interface IGrpcStockLocationRes {
  stock_locations: IWmsStockLocation[];
}

export interface IWmsStockLocationPayload extends IWmsStockLocationData {
  [index: string]: number;
}

export interface IWmsStockListPayload extends IWmsStockListData {
  [index: string]: string | number;
}

export interface IWmsStockLocation extends IBaseAudit {
  supermarket_id: number;
  zone_id: number;
  location_id?: number;
  product_id: number;
  product_variant_id?: number;
  units: number;
  zone?: IWmsZone;
  location?: IWmsLocation;
  product_variant?: IProductVariant;
  disable?: boolean;
}

export interface IWmsStock {
  product_id: number;
  units: number;
  location_id?: number;
  product_variant_id?: number;
}

export interface IWmsStockMove {
  product_id: number;
  units: number;
  src_zone_id: number;
  src_location_id?: number;
  dest_zone_id: number;
  dest_location_id?: number;
  product_variant_id?: number;
}

export interface IWmsStockLocationFilter {
  zone: string;
  location: string;
  units: string;
}

export enum WmsStockGridFilterEnum {
  PAGE = 'page',
  PRODUCT_NAME = 'product_name',
  SUPERMARKET_ID = 'supermarket_id'
}

export const stockLocationSelect = {
  id: true,
  product_variant_id: true,
  zone: {
    ...BaseSelect
  },
  location: {
    id: true,
    aisle: true,
    bay: true,
    level: true,
    division: true
  },
  units: true
};

export const wmsStockSelect = {
  id: true,
  supermarket_id: true,
  product_id: true,
  product_variant_id: true,
  available_units: true,
  reserved_units: true
};

export const adminStockLocationSelect = {
  id: true,
  supermarket_id: true,
  zone_id: true,
  location_id: true,
  product_id: true,
  product_variant_id: true,
  zone: {
    ...BaseSelect
  },
  location: {
    id: true,
    aisle: true,
    bay: true,
    level: true,
    division: true
  },
  units: true
};
