import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'ecomm-confirm-dialog',
  templateUrl: './confirm-dialog.component.html'
})
export class ConfirmDialogComponent {
  @Input() public title = '';
  @Input() public message = '';
  @Input() public isVisible = false;
  @Input() public isLoading = false;
  @Output() public confirmEvent = new EventEmitter<void>();
  @Output() public closeEvent = new EventEmitter<void>();

  public onConfirm(): void {
    this.confirmEvent.emit();
  }

  public onClose(): void {
    this.closeEvent.emit();
  }
}
