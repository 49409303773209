import { ColorPickerModule } from 'ngx-color-picker';

import { AbilityModule } from '@casl/angular';
import { DialogModule } from 'primeng/dialog';
import { AccordionModule } from 'primeng/accordion';
import { DropdownModule } from 'primeng/dropdown';
import { CalendarModule } from 'primeng/calendar';
import { MultiSelectModule } from 'primeng/multiselect';
import { TooltipModule } from 'primeng/tooltip';
import { ButtonModule } from 'primeng/button';
import { TableModule } from 'primeng/table';
import { TabViewModule } from 'primeng/tabview';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { ProgressBarModule } from 'primeng/progressbar';
import { StepsModule } from 'primeng/steps';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { OverlayPanelModule } from 'primeng/overlaypanel';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { BarcodeComponent } from '../common/component/barcode/barcode.component';
import { InputInfoTooltipComponent } from '../common/component/input-info-tooltip/input-info-tooltip.component';
import { PaginationComponent } from '../common/component/pagination/pagination.component';
import { ImageUploaderComponent } from './image-uploader/image-uploader.component';
import { ZipFileUploaderComponent } from '../module/tools/zip-file-uploader/zip-file-uploader.component';

import { FileDragAndDropDirective } from '../common/directive/file-drag-and-drop.directive';
import { DelayedInputDirective } from '../common/directive/delayed-input.directive';
import { RestrictInputDirective } from '../common/directive/restrict-input.directive';
import { DisableControlDirective } from '../common/directive/disable-control.directive';
import { InputInfoIconDirective } from '../common/directive/input-info-tooltip.directive';

import { RoleNamePipe } from '../common/pipe/employee-to-role-name.pipe';
import { BooleanToStringPipe } from '../common/pipe/boolean-to-string.pipe';
import { LocalDatePipe } from '../common/pipe/local-date.pipe';
import { SimplifyTextPipe } from '../common/pipe/simplify-text.pipe';
import { EnumToNamePipe } from '../common/pipe/enum-to-name.pipe';
import { AddHoursPipe } from '../common/pipe/add-hours.pipe';
import { FormatTimeSlotPipe } from '../common/pipe/format-time-slot.pipe';
import { CalendarDatePipe } from '../common/pipe/calendar-date.pipe';
import { SecondsToDurationPipe } from '../common/pipe/seconds-to-duration.pipe';
import { ConfirmDialogComponent } from '../common/component/confirm-dialog/confirm-dialog.component';

@NgModule({
  declarations: [
    FileDragAndDropDirective,
    DelayedInputDirective,
    RestrictInputDirective,
    DisableControlDirective,
    InputInfoIconDirective,
    BarcodeComponent,
    InputInfoTooltipComponent,
    PaginationComponent,
    ConfirmDialogComponent,
    ImageUploaderComponent,
    ZipFileUploaderComponent,
    BooleanToStringPipe,
    RoleNamePipe,
    LocalDatePipe,
    SimplifyTextPipe,
    EnumToNamePipe,
    AddHoursPipe,
    FormatTimeSlotPipe,
    CalendarDatePipe,
    SecondsToDurationPipe
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AccordionModule,
    DropdownModule,
    CalendarModule,
    MultiSelectModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    TabViewModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    StepsModule,
    CheckboxModule,
    RadioButtonModule,
    OverlayPanelModule,
    AbilityModule,
    ColorPickerModule
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DialogModule,
    AccordionModule,
    DropdownModule,
    CalendarModule,
    MultiSelectModule,
    TooltipModule,
    ButtonModule,
    TableModule,
    TabViewModule,
    ProgressSpinnerModule,
    ProgressBarModule,
    StepsModule,
    CheckboxModule,
    RadioButtonModule,
    OverlayPanelModule,
    AbilityModule,
    FileDragAndDropDirective,
    DelayedInputDirective,
    RestrictInputDirective,
    DisableControlDirective,
    InputInfoIconDirective,
    BarcodeComponent,
    InputInfoTooltipComponent,
    ConfirmDialogComponent,
    PaginationComponent,
    ImageUploaderComponent,
    ZipFileUploaderComponent,
    BooleanToStringPipe,
    RoleNamePipe,
    LocalDatePipe,
    SimplifyTextPipe,
    ColorPickerModule,
    EnumToNamePipe,
    AddHoursPipe,
    FormatTimeSlotPipe,
    CalendarDatePipe,
    SecondsToDurationPipe
  ]
})
export class SharedModule {}
