<p-dialog styleClass="confirm-modal" [modal]="true" [draggable]="false" [resizable]="false" [(visible)]="isVisible">
  <ng-template pTemplate="header">
    <h4 class="modal-title">{{ title }}</h4>
  </ng-template>
  <div class="modal-body text-center">
    <h3 class="info-message">{{ message }}</h3>
  </div>
  <ng-template pTemplate="footer">
    <div class="grid">
      <div class="col-12 text-center m-t-15 m-b-20">
        <button
          data-testid="confirm-button"
          type="button"
          class="btn btn-create modal-btn"
          [disabled]="isLoading"
          (click)="onConfirm()"
        >
          <span *ngIf="isLoading" class="btn-loader"></span> Yes
        </button>
        <button data-testid="close-button" type="button" class="btn btn-cancel" (click)="onClose()">No</button>
      </div>
    </div>
  </ng-template>
</p-dialog>
